import PropTypes from "prop-types";
import {CREATIONS_STEPS, WizardTaskCollection} from "../../services/async-api/WizardTaskCollection";
import {Button, Container, Divider, Drawer, Grid, IconButton, Paper} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {Delete} from "@material-ui/icons";
import {TASK_ACTIONS} from "../../services/async-api/InspectionItemTask";
import syncService from "../../services/async-api/SyncService";
import {ConfirmDialog} from "../ConfirmDialog";

export default function DutOverview(props) {


    const [, setTaskCollection] = useState(null);

    useEffect(() => {
        setTaskCollection(props.taskCollection);
    }, [props.taskCollection]);


    const formatDutNumber = (taskCollection: WizardTaskCollection) => {
        if(!taskCollection || taskCollection.taskCount() === 0) {
            return null;
        }
        if(taskCollection.taskCount() === 1) {
            return taskCollection.firstTask()?.dutNumberFormatted();
        }
        return taskCollection.firstTask()?.dutNumberFormatted() + ' - ' + taskCollection.lastTask().dutNumberFormatted();
    }

    const formatStatus = (taskCollection: WizardTaskCollection) => {
        if(!taskCollection || taskCollection.taskCount() === 0) {
            return null;
        }
        else if(taskCollection.taskCount() >= 1) {
            if(taskCollection.firstTask().inspectionItem.action) {
                return taskCollection.firstTask().inspectionItem.action;
            } else {
                return taskCollection.firstTask().inspectionItem.recommendation;
            }
        }
        return null;
    }

    const formatStatusHeader = (taskCollection: WizardTaskCollection) => {
        if(!taskCollection || taskCollection.taskCount() === 0) {
            return 'Aktion';
        } else if(taskCollection.taskCount() >= 1) {
            if(taskCollection.firstTask().inspectionItem.action) {
                return "Aktion";
            } else {
                return "Empfehlung";
            }
        }
    }

    const backToStep = (step) => {
        let tc = props.taskCollection.clone();
        tc.step = step;
        props.onTaskCollectionChanged(tc);
    }

    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper style={{padding: 10}}>
                    <Grid container>
                        <Grid container item xs={10} >
                            <Grid item xs={5} direction={"column"} container justify={"center"} justifyContent={"center"}>
                                <b>Betriebsmittel:</b>
                            </Grid>
                            <Grid item xs={7} direction={"column"} container justify={"center"} justifyContent={"center"}>
                                {props.taskCollection?.firstTask().inspectionItem.dut}
                            </Grid>
                        </Grid>
                        <EditComponent  action={() => {
                            backToStep(CREATIONS_STEPS.DUT_CHOOSE)
                        }}/>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper style={{padding: 10}}>
                    <Grid container>
                        <Grid container item xs={10} >
                            <OverViewItem nodivider title={'Anzahl'} value={props.taskCollection?.taskCount()}></OverViewItem>
                            <OverViewItem  title={'Nummer'.concat(props.taskCollection?.taskCount() > 1 ? 'n' : '')} value={formatDutNumber(props.taskCollection)}></OverViewItem>
                            <OverViewItem title={'Etage'} value={props.taskCollection?.firstTask().inspectionItem.floor}></OverViewItem>
                            <OverViewItem title={'Raum'} value={props.taskCollection?.firstTask().inspectionItem.room}></OverViewItem>
                            <OverViewItem title={'Schutzklasse'} value={props.taskCollection?.firstTask().inspectionItem.protectionClass}></OverViewItem>
                            <OverViewItem title={'Ergebnis'} value={props.taskCollection?.firstTask().inspectionItem.passed ? 'in Ordnung' : 'nicht in Ordnung'}></OverViewItem>
                        </Grid>
                        <EditComponent  action={() => {
                            backToStep(CREATIONS_STEPS.DUT_OPTIONS)
                        }}/>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper style={{padding: 10}}>
                    <Grid container>
                        <Grid container item xs={10} >
                            <OverViewItem nodivider
                                          title={props.taskCollection?.firstTask().inspectionItem.passed ? 'Hinweis' : 'Mangel'}
                                          value={props.taskCollection?.firstTask().inspectionItem.comment}></OverViewItem>
                        </Grid>
                        <EditComponent  action={() => {
                            backToStep(CREATIONS_STEPS.DUT_NOTICES)
                        }} />
                    </Grid>
                </Paper>
            </Grid>
                <Grid item xs={12}>
                    <Paper style={{padding: 10}}>
                        <Grid container>
                            <Grid container item xs={10} >
                                <OverViewItem nodivider
                                              title={formatStatusHeader(props.taskCollection)}
                                              value={formatStatus(props.taskCollection)}></OverViewItem>
                            </Grid>
                            <EditComponent action={() => {
                                backToStep(CREATIONS_STEPS.DUT_ACTIONS)
                            }} />
                        </Grid>
                    </Paper>
                </Grid>

        </Grid>
    )
}

function EditComponent(props) {
    return (
        <Grid container item xs={2} direction={"column"} justifyContent={"center"}>
            <Grid item container direction={"row"} justify={"flex-end"}>
                <IconButton onClick={props.action}><EditOutlinedIcon></EditOutlinedIcon></IconButton>
            </Grid>
        </Grid>
    )
}

export function OverViewItem(props) {
    return (
        <Grid style={{marginTop: '2px'}} container spacing={1}>
            {!props['nodivider'] && (
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
            )}
            <Grid item xs={props.vertical ? 12 : 5} direction={"column"} style={{overflowWrap: "anywhere"}} container justify={"center"} justifyContent={"center"}>
                <b>{props.title}{props.title.length > 0 ? ':' : ''}</b>
            </Grid>
            <Grid item xs={props.vertical ? 12 : 7} direction={"column"} container justify={"center"} justifyContent={"center"}>
                {props.value ? props.value : '-'}
            </Grid>


        </Grid>
    )
}OverViewItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    nodivider: PropTypes.bool,
    vertical: PropTypes.bool
}

export function DutOverviewFooter(props) {

    const saveAction = () => {
        let tc = props.taskCollection.clone();
        tc.step = CREATIONS_STEPS.DUT_FINISHED;
        props.onTaskCollectionChanged(tc);
    }
    const [editMode] = useState(props.taskCollection.firstTask()?.originalInspectionItem != null);
    const [buttonTitle, setButtonTitle] = useState(props.taskCollection.taskCount() > 1 ? 'Prüflinge speichern' : 'Prüfling speichern');
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if(props.taskCollection.firstTask().originalInspectionItem) {
            setButtonTitle('Änderungen speichern');
        }

    }, [props.taskCollection])

    return (
        <Grid container item xs={12} spacing={1} >
            <Grid item xs={3}>
                <Button onClick={() => {
                    let shouldDelete = window.confirm(`Wollen Sie den Eintrag mit Betriebsmittelnummer: ${props.taskCollection.lastTask().inspectionItem.dutNumberFormatted} wirklich löschen?`);
                    if(shouldDelete) {
                        props.taskCollection.lastTask().action = TASK_ACTIONS.DELETE;
                        syncService.submitSyncTasks(props.taskCollection.lastTask());
                        props.onDelete();
                    }
                    return;
                }} disabled={!editMode} color={"secondary"} variant={"contained"} fullWidth><Delete></Delete></Button>
            </Grid>
            <Grid item xs={9}>
                <Button
                    disabled={props.taskCollection.firstTask().originalInspectionItem?.equals(props.taskCollection.firstTask().inspectionItem)}
                    variant={"contained"} color={"primary"} onClick={() => {
                        if(props.taskCollection.tasks.length > 1) {
                            setDialogOpen(true)
                        } else {
                            saveAction()
                        }
                    }} fullWidth>{buttonTitle}
                </Button>
            </Grid>
            <Drawer anchor={'bottom'} open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <Container>
                    <Grid container style={{paddingTop: '20px', paddingBottom: '20px'}} justifyContent="space-between">
                        <Grid item xs={12}>
                            <p style={{fontSize: '20px'}}>Sollen wirklich <b>{props.taskCollection.tasks.length} Prüflinge</b> mit denselben Eigenschaften
                                gespeichert werden?</p>
                        </Grid>
                        <Grid item xs={3}>
                            <Button fullWidth variant={'contained'} color={'secondary'} onClick={() => {
                                setDialogOpen(false)
                            }}>Nein</Button>
                        </Grid>
                        <Grid item  xs={6}>
                            <Button fullWidth variant={'contained'} color={'primary'} onClick={() => {
                                setDialogOpen(false)
                                saveAction()
                            }}>Ja</Button>
                        </Grid>
                    </Grid>
                </Container>



            </Drawer>
            {/*<ConfirmDialog open={dialogOpen} title={'Mehre Prüflinge speichern'} message={`Sollen wirklich ${props.taskCollection.tasks.length} Prüflinge mit denselben Eigenschaften gespeichert werden?`} handleConfirm={(confirm: boolean) => {*/}
            {/*    if(confirm) {*/}
            {/*        saveAction()*/}
            {/*    } else {*/}
            {/*        setDialogOpen(false);*/}
            {/*    }*/}
            {/*}}></ConfirmDialog>*/}
        </Grid>
    );
}

DutOverview.propTypes = {
    taskCollection: PropTypes.instanceOf(WizardTaskCollection).isRequired,
    onTaskCollectionChanged: PropTypes.func.isRequired
}
