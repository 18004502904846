import {Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import baseDataService from '../services/BaseDataService';
import {QrReader} from "react-qr-reader";
import BaseDataService from "../services/BaseDataService";

const useStyles = makeStyles((theme) => ({
    qrReader: {
        width: '100%',
        border: '1px solid gray',
        marginBottom: theme.spacing(2)
    },
    chooseMeasuringDeviceAlert: {
        marginBottom: theme.spacing(2)
    },
    dialogPaper: {
        minWidth: '400px'
    },
}));

export default function ChooseMeasuringDevice(props) {
    const [serialNumber, setSerialNumber] = useState(process.env.REACT_APP_DEVELOPMENT === 1 ? '300' : '');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [measuringDevice, setMeasuringDevice] = useState(null);
    const [successfulScan, setSuccessfulScan] = useState(false);
    const [qrReader, setQrReader] = useState(true);
    const classes = useStyles();


    useEffect(() => {
        baseDataService.checkLastMeasuringDeviceSerialNumber().then(value => {
            if(value) {
                setSerialNumber(value);
            }
        })
    }, []);

    const selectMeasuringDevice = (serialNumber) => {
        for (const measuringDevice of baseDataService.measuringDevices) {
            console.log(measuringDevice)
            if (measuringDevice.serialNumber == serialNumber) {
                setMeasuringDevice(measuringDevice);
                return;
            }
        }
        setMeasuringDevice(null);
    }

    useEffect(() => {
        if(serialNumber && serialNumber.length > 0) {
            selectMeasuringDevice(serialNumber);
            setSuccessfulScan(true)
        } else {
            setSuccessfulScan(false)
        }

    }, [serialNumber]);

    const onScanned = (data) => {
        if (!data) {
            return;
        }
        setShowErrorMessage(false);

        setSuccessfulScan(true);
        setSerialNumber(data);
        selectMeasuringDevice(data);
    }

    const onError = () => {
        setQrReader(false);
    }

    const handleOnChange = (e) => {
        setShowErrorMessage(false);
        setSerialNumber(e.target.value);
        selectMeasuringDevice(e.target.value);
        if(!e.target.value) {
            setSuccessfulScan(false);
        } else {
            setSuccessfulScan(true);
        }
    }

    const handleClose = () => {
        setShowErrorMessage(false);
        setSerialNumber('');
        setMeasuringDevice(null);
        props.onCancelled();
    }

    const handleConfirm = () => {
        if (!measuringDevice) {
            setShowErrorMessage(true);
            return;
        }
        props.onSelected(measuringDevice);
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="alert-dialog-slide-title">{"Messgerät wählen"}</DialogTitle>
            <DialogContent>
                {showErrorMessage &&
                <Alert severity="error" className={classes.chooseMeasuringDeviceAlert}>
                    Messgerät nicht gefunden!
                </Alert>}
                {qrReader &&
                <QrReader videoStyle={{objectFit: 'cover'}} onResult={onScanned}  constraints={{facingMode: 'environment'}}
                          className={classes.qrReader}/>}
                <TextField autoFocus label={'Seriennummer'} value={serialNumber} onChange={handleOnChange} onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        handleConfirm();
                        ev.preventDefault();
                    }
                }} fullWidth/>
            </DialogContent>
            <DialogActions style={{display: 'flex'}}>
                <Button onClick={handleClose} color="secondary">Abbrechen</Button>
                <div style={{flexGrow: 1}}/>
                <Button onClick={handleConfirm} color="primary" disabled={!successfulScan} variant={'contained'}>
                    Beginnen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ChooseMeasuringDevice.propTypes = {
    onSelected: PropTypes.func.isRequired,
    onCancelled: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}
