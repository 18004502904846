import {
    Button,
    Grid, Paper,
    TextField
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {NOTICE_TYPE_FLAW, NOTICE_TYPE_NOTICE} from "../../services/dto/Notice";
import baseDataService from "../../services/BaseDataService";
import {CREATIONS_STEPS, WizardTaskCollection} from "../../services/async-api/WizardTaskCollection";
import PropTypes from "prop-types";
import ColoredList from "./ColoredList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SyncService from "../../services/async-api/SyncService";
import {ArrowForward} from "@material-ui/icons";


export default function DutNotices(props) {

    const [notices, setNotices] = useState([]);
    const [flaws, setFlaws] = useState([]);
    const [selectedNotice, setSelectedNotice] = useState('');
    const [textBoxLabel, setTextBoxLabel] = useState('Mangel/Hinweis');

    const [isNotice, setIsNotice] = useState()

    const [autoCompleteOptionsFlaws, setAutoCompleteOptionsFlaws] = useState([])
    const [autoCompleteOptionsNotice, setAutoCompleteOptionsNotice] = useState([])

    const inputRef = React.useRef();

    useEffect(() => {
        let tempNotices = baseDataService.notices;
        let tFlaws = [];
        let tNotices = [];
        tempNotices.forEach((notice) => {
            if(notice.type === NOTICE_TYPE_NOTICE) {
                tNotices.push(notice);
            } else if(notice.type === NOTICE_TYPE_FLAW) {
                tFlaws.push(notice);
            }
        });
        setNotices(tNotices);
        setFlaws(tFlaws)

        let autoCompleteNotices = [];
        let autoCompleteFlaws = [];

        let items = SyncService.currentInspection.items.sort((a, b) => ('' + b.updatedAt).localeCompare(a.updatedAt)).reverse();

        let filterFlaws = tFlaws.map(value => {
            return value.text;
        })
        let filterNotices = tNotices.map(value => {
            return value.text
        })

        for (const item of items) {
            if(item.comment && item.comment.length > 0) {
                if(item.passed) {
                    if(!filterNotices.includes(item.comment)) {
                        autoCompleteNotices.push(item.comment);
                    }
                } else {
                    if(!filterFlaws.includes(item.comment)) {
                        autoCompleteFlaws.push(item.comment);
                    }

                }
            }
        }
        setAutoCompleteOptionsNotice([...new Set(autoCompleteNotices)]);
        setAutoCompleteOptionsFlaws([...new Set(autoCompleteFlaws)]);

    }, [])

    useEffect(() => {
        setSelectedNotice(props.taskCollection.firstTask().inspectionItem.comment);
        setTextBoxLabel(props.taskCollection.firstTask().inspectionItem.passed ? 'Hinweis' : 'Mangel');
        setIsNotice(props.taskCollection.firstTask().inspectionItem.passed)
        // if(props.taskCollection.tasks.length > 0) {
        //     changeNotice('', isNotice);
        // }
    }, [props.taskCollection])

    const selectFlaw = (flaw) => {
        changeNotice(flaw, false);
    }
    const selectNotice = (notice) => {
        changeNotice(notice, true);
    }

    const changeNotice = (notice, passed) => {
        setIsNotice(passed);
        setSelectedNotice(notice);

        setTextBoxLabel(passed ? 'Hinweis' : 'Mangel');
        let tc = props.taskCollection.clone();
        tc.firstTask().inspectionItem.passed = passed;
        tc.firstTask().inspectionItem.comment = notice;

        for (const task of tc.tasks) {
            task.inspectionItem.passed = passed;
            task.inspectionItem.comment = notice;
        }

        props.onTaskCollectionChanged(tc);
    }

    const handleFreeTextEntry = (isNotice: boolean) => {
        changeNotice('', isNotice);
        inputRef.current.focus();
    }

    const changeSelectedNotice = (evt, value) => {
        // alert(value);
        console.log(value);
        changeNotice(value, props.taskCollection.firstTask().inspectionItem.passed);
    }

    const CustomPaper = (props) => {
        return <Paper style={{backgroundColor: "#0f385f", color:'white'}} elevation={8} {...props} />;
    };

    return (
         <Grid container style={{height: '100%'}} spacing={2}>
             <Grid item xs={12} style={{height: 'calc(100% - 70px)'}}>
                 <Grid container spacing={1} style={{height: '100%'}}>
                     <Grid item container xs={6} style={{height: '100%'}}>
                         <Grid item xs={12}>
                             <h3 style={{margin: 0}}>Mangel</h3>
                         </Grid>
                         <Grid item xs={12} style={{height: 'calc(100% - 45px)', paddingRight:10 ,overflow: 'scroll', overflowX: 'hidden'}}>
                             <ColoredList disabled={props.taskCollection.tasks.length > 1} onFreeTextEntrySelected={() => {handleFreeTextEntry(false)}} freeTextEntry={true}
                                          selectedValue={selectedNotice} values={flaws.map((flaw)=>{return flaw.text})} color={'#c23535'}
                                          callback={selectFlaw}></ColoredList>
                         </Grid>

                     </Grid>
                     <Grid item container xs={6} style={{height: '100%'}}>
                         <Grid item xs={12}>
                             <h3 style={{margin: 0}}>Hinweis</h3>
                         </Grid>
                         <Grid item xs={12} style={{height: 'calc(100% - 45px)', paddingRight:10 ,overflow: 'scroll', overflowX: 'hidden'}}>
                             <ColoredList onFreeTextEntrySelected={() => {handleFreeTextEntry(true)}} freeTextEntry={true}
                                          selectedValue={selectedNotice} values={notices.map((notice)=>{return notice.text})} color={'#d4d4d4'}
                                          callback={selectNotice}>
                             </ColoredList>
                         </Grid>
                     </Grid>
                 </Grid>
             </Grid>
             <Grid item xs={8} md={10} style={{marginTop: '0px'}}>
                 {/*<TextField  value={selectedNotice} onChange={changeSelectedNotice} variant={"outlined"} label={textBoxLabel} fullWidth ></TextField>*/}
                 <Autocomplete value={selectedNotice} PaperComponent={CustomPaper} onInputChange={(evt, value) => {
                     changeSelectedNotice(evt, value);
                 }}  freeSolo renderInput={(params) => (
                     <TextField {...params} inputRef={inputRef} label={textBoxLabel} margin="normal" variant="outlined" fullWidth />
                 )} options={isNotice ? autoCompleteOptionsNotice : autoCompleteOptionsFlaws} />
             </Grid>
             <Grid item xs={4} md={2}>
                 <Button onClick={() => {
                     let tc = props.taskCollection.clone();
                     tc.step = CREATIONS_STEPS.DUT_ACTIONS;
                     props.onTaskCollectionChanged(tc);
                 }} disabled={!selectedNotice}  variant={"contained"} style={{height: '54px', marginTop: '17px', padding: '5px'}} color={'primary'} fullWidth><ArrowForward fontSize={"large"} /></Button>
             </Grid>
         </Grid>
    )
}

DutNotices.propTypes = {
    taskCollection: PropTypes.instanceOf(WizardTaskCollection).isRequired,
    onTaskCollectionChanged: PropTypes.func.isRequired
}

// export function DutNoticesFooter(props) {
//
//     const [forwardButtonEnabled, setForwardButtonEnabled] = useState(props.taskCollection.firstTask()?.inspectionItem.comment)
//
//     const forwardAction = () => {
//         let tc = props.taskCollection.clone();
//         tc.step = CREATIONS_STEPS.DUT_ACTIONS;
//         props.onTaskCollectionChanged(tc);
//     }
//
//     useEffect(() => {
//         console.log(props.taskCollection);
//         setForwardButtonEnabled(props.taskCollection.firstTask()?.inspectionItem.comment);
//     }, [props.taskCollection]);
//
//     return (
//         <Grid container item xs={12} >
//             <Button variant={"contained"} disabled={!forwardButtonEnabled} color={"primary"} onClick={forwardAction} fullWidth>Übernehmen</Button>
//         </Grid>
//     )
// }


